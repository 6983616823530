<template>
    <div class="w-full">
        <h4 class="text-sm font-bold text-black mb-3 pl-5">Modification d'un compte utilisateur</h4>

        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full h-[950px]">
            <form action="" class="mb-10" @submit.prevent="getUser">
                <label for="search_user" class="block text-gray-700 font-bold mb-2">Utilisateur</label>
                <select id="search_user" @change="getUser" v-model="search_user" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-right">
                    <option v-for="(item,index) in data" :key=index :value="item.id">
                        {{item.titre?.nom+' '+item.nom+' ('+item.phone+')'}}
                    </option>
                </select>
            </form>
                            
            <form  v-if="search_user!=null" @submit.prevent="change_user">
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="nom">
                    Nom (*)
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="nom"
                    type="text"
                    maxlength="60"
                    required=true
                    placeholder="Entrez le nom de l'utilisateur"
                    v-model="nom"
                    />
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="phone">
                    Téléphone (*)
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    maxlength="13"
                    required=true
                    placeholder="Entrez le téléphone de l'utilisateur (ex : +243812731458)"
                    v-model="phone"
                    />
                    
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="phone">
                    Email
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Entrez l'adresse email de l'utilisateur"
                    v-model="email"
                    />
                    
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="role">
                    Rôle
                    </label>
                    <Select @change="role = $event.target.value" onchange="getUser" v-model="role" class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]" id="role"
                    
                    required="true"
                    >
                    <option :value="role">{{ role=="sadmin"?"Super admin":"Admin" }}</option>
                    <option value="sadmin">Super admin</option>
                    <option value="admin">Admin</option>
                    </Select>
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="titre_id">
                    Titre
                    </label>
                    <Select @change="titre_id = $event.target.value"  v-model="titre_id" class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]" id="role"
                    
                    required="true"
                    >
                    <option v-if="found_user!=null && found_user.titre!=null" :value="found_user.titre.id">{{found_user.titre.nom}}</option> 
                    <option value=""></option>
                    <option v-for="(item,index) in titres" :key=index :value="item.id" >{{item.nom}}</option>
                    </Select>
                </div>

                <!-- <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="image">
                    Image
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="image"
                    type="file"
                    accept="image/*"
                    @change="handleFileUpload"
                    />
                </div> -->
                <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                <div class="flex items-center justify-between">
                    <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    >
                    Modifier
                    </button>

                    <button v-if="statut=='activé'"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment bloquer ce compte ?')"
                    >
                    Bloquer
                    </button>
                    <button v-else
                    class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment débloquer ce compte ?')"
                    >
                    Débloquer
                    </button>
                </div>
            </form>
        </div>
                
    </div>
</template>

<script>

import axios from 'axios'
export default {
    components:
    {
        
    },
    data() {
      
      return {
        token:localStorage.getItem('authToken'),
        data:null,
        found_user:null,
        statut:'',
        nom:'',
        phone:'',
        titres:null,
        search_user:null,
        email:'',
        // image:null,
        role:'',
        erreur:'',
        isErreur:false,
        //isLoding:false
      }
    },
    beforeUnmount()
    {
        this.getUsers()
        this.getTitres()
        
    },
    mounted()
    {
        this.getUsers()
        this.getTitres()
        
    },
    methods:{
    // handleFileUpload(event) {
    //   this.image = event.target.files[0]
      
    // },
    getTitres()
      {
          axios.get(this.$serverConstant+'backoffice/titres',{
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
          }).then(response=>{
              this.titres=response.data.data
            
        }).catch(error=>{
  
  this.erreur=error.message
  console.log(error)
  })
      },
    
    
    getUsers()
    {
        axios.get(this.$serverConstant+'backoffice/users',{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            this.data=response.data.data
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    getUser()
    {
        
        axios.get(this.$serverConstant+'backoffice/users/'+this.search_user,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            
            
            this.found_user=response.data.data
            this.nom=this.found_user.nom,
            this.phone=this.found_user.phone,
            this.email=this.found_user.email,
            this.statut=this.found_user.statut,
            this.role=this.found_user.roles[0].name
            
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    change_user()
    {
        
        var data={
            nom:this.nom,
            role:this.role,
    
        }
        if(this.phone!=this.found_user.phone)
        {
            data.phone=this.phone
        }
        if(this.email!=this.found_user.email)
        {
            data.email=this.email
        }
        if(this.titre_id!=this.found_user.titre?.id)
        {
            data.titre_id=this.titre_id
        }

        // if(this.image!=null)
        // {
        //     data.image=this.image
        // }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'backoffice/users/'+this.search_user,data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              this.erreur="compte modifié avec succès"
            
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
    },
    confirmation(message)
    {
        if(confirm(message))
        {
           this.blockage(this.found_user.id)
        }
    },

    blockage(id)
    {
        axios.get(this.$serverConstant+'backoffice/users/block/'+id,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
    }).then(response=>{
            //requete arrivé dans l'api
            
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              if(this.found_user.statut=="bloqué")
              {
                this.erreur="compte débloqué avec succès"
              }
              else
              {
                this.erreur="compte bloqué avec succès"
              }
              
              this.getUser()
            
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
        
    }

    
}
      

}
</script>

<style>

</style>