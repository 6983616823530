<template>
  <div class="w-full p-O m-0" v-if="user_role=='sadmin'">
    <div class="min-h-screen h-full w-[96%] mx-[2%] my-[3%] px-[2%] py-[3%] rounded  shadow-gray-300 shadow-sm">
        <h4 class="text-3xl text-black font-bold mb-[3%]">Gestion des utilisateurs</h4>
        <div class="block md:flex">
          <AddUserVue/>
          <UpdateUserVue/>
        </div>
  </div>
  </div>
</template>

<script>
import AddUserVue from './AddUserVue.vue';
import UpdateUserVue from './UpdateUserVue.vue';

    export default{
      components:
      {
        AddUserVue,
        UpdateUserVue
      },
      data()
      {
        return {
          user_role:localStorage.getItem('role'),
        }
      }
    }
</script>

<style>

</style>