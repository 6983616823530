<template>
    <div class="mt-[5%] bg-black bg-opacity-90 h-full" id="contacts">
        <div class="h-full w-full bg-blue-800 bg-opacity-20">
            <div class="flex mx-[3%] py-[5%]">
                <div class="text-white w-[40%] md:w-[20%]">
                    <div class="mb-5">
                        <h4 class="text-white font-bold text-xl md:text-3xl  mb-5">Contact</h4>
                        <div class="">
                            <span class="block text-xs md:text-sm lg:text-lg">+243 97 680 49 96</span>
                            <span class="block text-xs md:text-sm lg:text-lg">+243 81 348 91 22</span>
                            <span class="block text-xs md:text-sm lg:text-lg">+243 99 43 692 18</span>
                        </div>
                    </div>
                    <div class="mb-5">
                        <h4 class="text-white font-bold text-xl md:text-3xl mb-5">Email</h4>
                            
                        <div class="">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 gmail text-white mr-4 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg> -->

                            <span class="text-white text-xs md:text-sm lg:text-lg">info@cacod.net</span><br>
                            
                            <span class="text-white text-xs md:text-sm lg:text-lg">espetubale@gmail.com</span>
                            
                            
                        </div>
                        
                    </div>

                    <div class="mb-5">
                        <h4 class="text-white font-bold text-xl md:text-3xl mb-5">Suivez-nous</h4>
                        <div class="">
                            <a href="https://facebook.com/cacod.org" class="text-xs md:text-sm lg:text-lg text-white hover:text-gray-200 mr-4 hover:text-green-500">
                                <svg class="w-6 h-6 fill-current inline mr-1" viewBox="0 0 24 24">
                                    <path d="M12 2.04c-5.5 0-10 4.49-10 10.01 0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10.06 10.06 0 0 0 8.44-9.9c0-5.52-4.5-10.01-10-10.01z"/>
                                </svg> 
                                 <span class="text-white text-xs md:text-sm lg:text-lg">cacod.org</span>
                             </a>
                        </div>

                        <div class="mt-7">
                            <img src="./../assets/logo.png" class="rounded rounded-lg">
                        </div>
                        
                        
                    </div>
                </div>
                <div class="text-white  ml-[3%] md:ml-[10%] w-full px-[3%] content-center">
                    <h4 class="font-bold  text-xl md:text-3xl mb-10">Pour nous contacter</h4>
                    <form @submit.prevent="send_mail">
                        <p class="text-xs md:text-sm lg:text-lg">Vous pouvez soit remplir ce formulaire, soit nous appeler, soit nous écrire par mail</p>
                        <div class="flex w-full mt-10">
                            <div class="w-[50%]">
                                <label class="block text-xs md:text-sm lg:text-lg mb-4" for="nom">Prénom, nom et postnom (*)</label>
                                <input type="text" required v-model="nom" id="nom" class="h-10 p-2 text-black text-xs md:text-sm lg:text-lg rounded w-full">
                            </div>
                            <div class="ml-[10%] w-[50%]">
                                <label class="block text-xs md:text-sm lg:text-lg mb-4" for="entreprise">Entreprise ou Organisation</label>
                                <input type="text" v-model="entreprise" id="entreprise" class="h-10 p-2 text-black text-xs md:text-sm lg:text-lg rounded w-full">
                            </div>
                        </div>

                        <div class="flex w-full mt-10">
                            <div class="w-[50%]">
                                <label class="block text-xs md:text-sm lg:text-lg mb-4" for="email">Email (*)</label>
                                <input type="email" v-model="email" required id="email" class="h-10 p-2 text-black text-xs md:text-sm lg:text-lg rounded w-full">
                            </div>
                            <div class="ml-[10%] w-[50%]">
                                <label class="block text-xs md:text-sm lg:text-lg mb-4" for="phone">Téléphone</label>
                                <input type="text" v-model="phone" id="phone" class="h-10 p-2 text-black text-xs md:text-sm lg:text-lg rounded w-full">
                            </div>
                        </div>
                        <div class=" w-full mt-10">
                            <label class="block text-xs md:text-sm lg:text-lg mb-4" for="message">Message (*)</label>
                            <textarea v-model="message" required id="message" cols="30" rows="10" class="w-full rounded p-2 text-black text-xs md:text-sm lg:text-lg"></textarea>
                        </div>
                        <div>
                            <p v-if="isErreur==true" class="text-red-500 text-xs md:text-sm lg:text-lg truncate w-full mb-4">{{ erreur }}</p>
                            <p v-if="isErreur==false" class="text-green-500 text-xs md:text-sm lg:text-lg truncate w-full mb-4">{{ erreur }}</p>
                            <button type="submit" class="text-white font-bold text-xs md:text-sm lg:text-lg bg-green-900 hover:bg-green-500 p-2 rounded my-10">Envoyer</button>
                        </div>
                </form>
                    
                </div>
                
            </div>
            <p class="text-xs md:text-sm lg:text-lg text-white w-full text-center"><span class="text-xs md:text-sm lg:text-lg text-white mr-1">&copy;</span>Graphic Avenir 2024</p>
            <p class="hidden text-xs md:text-sm lg:text-lg">Dévélopé par Jeremie Kamuina Nsapu, Un site web vitrine pour le compte de l'ONG CACOD</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data()
    {
        return {
            nom:'',
            email:'',
            phone:'',
            entreprise:'',
            message:'',
            erreur:'',
            isErreur:false
        }
    },
    methods:{
        
        send_mail()
        {
            var data={
                nom:this.nom,
            email:this.email,
            phone:this.phone,
            entreprise:this.entreprise,
            message:this.message,

            }
            axios.post(this.$serverConstant+'send_mail',data).then(response=>{
                this.erreur=response.data.message
                this.isErreur=false
                this.message=""
            }).catch(error=>{
                this.erreur=error.response.data.message
                this.isErreur=true
            })
        }
    }
}
</script>

<style>

</style>