<template>
    <MenuVue/>
    <div class="bg-gray-100 py-[5%] mb-[10%] h-max">
      <div class="container mx-auto px-4">
        <h1 class="text-3xl font-bold text-center mb-14 text-green-900">
          Envoyez vos dons
        </h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div
            v-for="account in accounts"
            :key="account.id"
            class="bg-white rounded-lg shadow-md p-6 flex flex-col items-center"
          >
            <img :src="account.logo" :alt="account.name" class="w-16 h-16 mb-4">
            <p class="text-lg font-bold mb-2">{{ account.name }}</p>
            <p class="text-gray-600 mb-4">{{ account.number }}</p>
            <!-- <a
              :href="account.link"
              target="_blank"
              class="bg-gray-900 text-white px-4 py-2 rounded-md hover:bg-gray-800 transition-colors duration-300"
            >
              Faire un don
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <FooterVue/>
  </template>
  
  <script>
  import MenuVue from '../MenuVue.vue'
  import FooterVue from '../FooterVue.vue'
  export default {
    name: 'DonationPage',
    components:{
      MenuVue,  
      FooterVue
    },
    data() {
      return {
        accounts: [
          {
            id: 1,
            name: 'airtel Money',
            number: '+243 97 680 49 96',
            logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRbh7io2V3LUdK-qd84T8BlZSuK7axOfesjPQAfT9JgUOH4WJLBDIfj0SYLUe-yHRv5rE&usqp=CAU',
            link: '#'
          },
          // {
          //   id: 2,
          //   name: 'PayPal',
          //   number: 'example@email.com',
          //   logo: 'https://via.placeholder.com/50x50',
          //   link: '#'
          // },
          // {
          //   id: 3,
          //   name: 'Virement bancaire',
          //   number: 'IBAN: FR12 3456 7890 1234 5678 90',
          //   logo: 'https://via.placeholder.com/50x50',
          //   link: '#'
          // },
          // {
          //   id: 4,
          //   name: 'Compte Paypal',
          //   number: 'example@email.com',
          //   logo: 'https://via.placeholder.com/50x50',
          //   link: '#'
          // },
          // {
          //   id: 5,
          //   name: 'Compte bancaire 2',
          //   number: '987-654-3210',
          //   logo: 'https://via.placeholder.com/50x50',
          //   link: '#'
          // },
          // {
          //   id: 6,
          //   name: 'Virement bancaire 2',
          //   number: 'IBAN: FR98 7654 3210 9876 5432 10',
          //   logo: 'https://via.placeholder.com/50x50',
          //   link: '#'
          // }
        ]
      }
    }
  }
  </script>