<template>
  <div  class="w-full h-max py-[4%] content-center" id="buts">
        <div class="h-[50%] mx-[5%]">
            <h1 class="text-3xl md:text-5xl font-bold text-green-900  mb-[2%]">But, Missions et Finalités</h1>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    <ButVue/>
                    <MissionVue/>
                    <FinaliteVue/>
            </div>

        </div>
  </div>
</template>

<script>

import ButVue from './ButVue.vue' 
import MissionVue from './MissionVue.vue' 
import FinaliteVue from './FinaliteVue.vue' 
export default {
    components:
    {
        ButVue,
        MissionVue,
        FinaliteVue
    }
}
</script>

<style>

</style>