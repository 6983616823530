<template>
    <div class="flex justify-center items-center h-screen bg-gray-200">
      <div class="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <h1 class="text-2xl font-bold mb-6 text-center"  @dblclick="goToHome"> Connexion</h1>
        <form class="space-y-4" @submit.prevent="login">
          <div>
            <label for="phone" class="block font-medium mb-1">Téléphone</label>
            <input
              type="tel"
              id="phone"
              v-model="phone"
              class="w-full border border-gray-300 rounded-md px-3 py-2 focus:border-gray-500 focus:outline-none"
              placeholder="Entrez votre numéro de téléphone"
              required
            />
          </div>
          <div>
            <label for="password" class="block font-medium mb-1">Mot de passe</label>
            <input
              type="password"
              id="password"
              v-model="password"
              class="w-full border border-gray-300 rounded-md px-3 py-2 focus:border-gray-500 focus:outline-none"
              placeholder="Entrez votre mot de passe"
              required
            />
          </div>
          
          <p class="text-red-500 text-sm truncate w-full">{{ erreur }}</p>
          
          <button
            type="submit"
            class="w-full bg-green-900 text-white py-2 rounded-md hover:bg-green-800 transition-colors duration-300"
          >
            Se connecter
          </button>
          <!-- <button 
            class="w-full bg-gray-400 text-white py-2 px-4 rounded disabled:bg-gray-400 disabled:text-white disabled:cursor-not-allowed"
            :disabled=true
          > Traitement en cours ...
          </button> -->
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'LoginPage',
    data() {
      
      return {
        phone:'',
        password:'',
        erreur:'',
        //isLoding:false
      }
    },
    methods: {
       login() {
        //this.isLoding=true
        axios.post(this.$serverConstant+'login',{
          phone:this.phone,password:this.password
        }).then(response=>{
          
            //requete arrivé dans l'api
            
            if(response.data.status_code==200)
            {
              var user_data=response.data.data
              //L'opération a réussit nous allons enregistrer la session de l'utilisateur
              localStorage.setItem('authToken',user_data.access_token)
              localStorage.setItem('username',user_data.user.nom)
              localStorage.setItem('phone',user_data.user.phone)
              localStorage.setItem('image',user_data.user.image)
              localStorage.setItem('email',user_data.user.email)
              localStorage.setItem('user_id',user_data.user.id)
              var role=user_data.user.roles[0].name
              localStorage.setItem('role',role)
              if(role=="sadmin")
              {
                // function_id est l'identifiant de l'Objet qui hérite du User selon son rôle
                localStorage.setItem('function_id',user_data.user.sadmin.id)
              }
              else if(role=="admin")
              {
                // function_id est l'identifiant de l'Objet qui hérite du User selon son rôle
                localStorage.setItem('function_id',user_data.user.admin.id)
              }
              else if(role=="membre")
              {
                // function_id est l'identifiant de l'Objet qui hérite du User selon son rôle
                localStorage.setItem('function_id',user_data.user.membre.id)
              }
              else if(role=="etudiant")
              {
                // function_id est l'identifiant de l'Objet qui hérite du User selon son rôle
                localStorage.setItem('function_id',user_data.user.etudiant.id)
              }
              else if(role=="client")
              {
                // function_id est l'identifiant de l'Objet qui hérite du User selon son rôle
                localStorage.setItem('function_id',user_data.user.client.id)
              }
              this.$router.push('/back-office')
              
            }
            else
            {
              //L'opération a échoué

              this.erreur=response.data.message
            }
            
           
          
          
        }).catch(error=>{

          // Une erreur dans l'api
          this.erreur=error.response.data.message
          
        })
        this.password=""
      },
       
            
      goToHome()
      {
        this.$router.push('/')
      }
    }
  }
  </script>