<template>
  
            <div class="w-full md:w-[50%]">
                <h4 class="text-sm font-bold text-black mb-3 pl-5">Ajout d'une nouvelle activité</h4>
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full md:max-w-md h-[950px]" >
                    <form class="" @submit.prevent="new_activity">
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="title_act_add">
                        Titre (Max: 50 caractères)
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="title_act_add"
                        type="text"
                        maxlength="50"
                        required=true
                        placeholder="Entrez le titre"
                        v-model="titre"
                        />
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="content_act_add">
                        Contenu (Max: 100 caractères)
                        </label>
                        <div ref="editorElement">
                          <TiptapVue/>
                        </div>
                   
                        
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="message_act_add">
                        Message (Max: 100 caractères)
                        </label>
                        <textarea
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="message_act_add"
                        rows="3"
                        placeholder="Entrez le message"
                        maxlength="100"
                        v-model="message"
                        ></textarea>
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="btntext_act_add">
                        Texte du bouton (Max: 15 caractères)
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="btntext_act_add"
                        placeholder="Entrez le texte du bouton d'action"
                        maxlength="15"
                        v-model="btntext"
                        >
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="image_act_add">
                        Image
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="image_act_add"
                        type="file"
                        accept="image/*"
                        @change="handleFileUpload"
                        />
                    </div>
                    <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                    <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                    <div class="flex items-center justify-between">
                        <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        >
                        Enregistrer
                        </button>
                    </div>
                </form>
                </div>
                
            </div>
            
</template>

<script>
import TiptapVue from '../../TiptapVue.vue'
import axios from 'axios'
export default {
    components:{
        TiptapVue,
    },
    data() {
      
      return {
        token:localStorage.getItem('authToken'),
        titre:'',
        contenu:'',
        message:'',
        btntext:'',
        image:null,
        erreur:'',
        isErreur:false,
        //isLoding:false
      }
    },
  methods:{
    handleFileUpload(event) {
      this.image = event.target.files[0]
      
    },
    new_activity()
    {
        
    
        

        var data={
            titre:this.titre,
            contenu:document.querySelectorAll(".div_contenu")[0].querySelector('p').innerHTML,
            message:this.message,
            btntext:this.btntext,
            image:this.image

        }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'backoffice/activity',data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            if(response.data.status_code==200)
            {
              //var user_data=response.data.data
              this.isErreur=false
              this.erreur="Activité ajoutée avec succès"
              this.titre=''
             
              

            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
    }
  }
}
</script>

<style>

</style>