import { createApp } from 'vue';
import App from './App.vue';
import Home from './components/Vues/HomeVue.vue';
import President from './components/Vues/PresidentPageVue.vue';
import Login from './components/Vues/LoginPageVue.vue';
import Don from './components/Vues/DonPageVue.vue';
import BackOffice from './components/Vues/BackOfficeVuePage.vue';
import './index.css';
import { createRouter, createWebHistory } from 'vue-router';
import VueScrollTo from 'vue-scrollto';

// Constante globale
export const SERVER_CONSTANT = 'https://api.cacod.net/public/api/';
export const STORAGE_CONSTANT ='https://api.cacod.net/public/storage/';

// export const SERVER_CONSTANT = 'http://localhost:8000/api/';
// export const STORAGE_CONSTANT ='http://localhost:8000/storage/';
//Cette fonction vérifie si l'utilisateur est connecté
function checkAuth() {
  const authToken = localStorage.getItem('authToken');
  return authToken && authToken.trim() !== '';
}

function checkAdmin() {
  const role = localStorage.getItem('role');
  return role === "admin" || role === "sadmin";
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Home
    },
    {
      path: "/president",
      component: President
    },
    {
      path: "/login",
      component: Login,
      beforeEnter: (to, from, next) => {
        if (checkAuth()) {
          if (from.path === "/login") {
            // L'utilisateur est connecté et se trouve déjà sur la page de connexion, ne faites rien
            next();
          } else {
            // L'utilisateur est connecté, redirigez-le vers la page d'accueil
            next("/");
          }
        } else {
          // L'utilisateur n'est pas connecté, laissez-le accéder à la page de connexion
          next();
        }
      }
    },
    {
      path: "/don",
      component: Don
    },
    {
      path:"/back-office",
      component:BackOffice,
      beforeEnter: (to, from, next) => {
        if (checkAuth() && checkAdmin()) {
          next(); // L'utilisateur est connecté, laissez-le accéder à la route
        } else {
          next('/login'); // L'utilisateur n'est pas connecté, redirigez-le vers la page de connexion
        }
      }
    }
  ]
});

const app = createApp(App);
app.use(router);
app.use(VueScrollTo);

// Vous pouvez maintenant accéder à GLOBAL_CONSTANT dans tout votre code
app.config.globalProperties.$serverConstant = SERVER_CONSTANT;
app.config.globalProperties.$storageConstant = STORAGE_CONSTANT;

app.mount('#app');