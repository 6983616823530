<template>
    <div class="h-max bg-white font-medium font-serif w-full m-0 p-0" id="mainMenu">
        <div class="h-max bg-green-600 py-1 w-full m-0">
            <h2 class="text-center font-bold text-white text-xl md:text-2xl"  @dblclick="goToLogin">ONGD/ASBL</h2>
            <h3 class="text-center font-bold text-white text-xs md:text-sm">CENTRE D’ACTIONS COMMUNES POUR LE DEVELOPPEMENT DURABLE</h3>
            <!--h2 class="text-center font-bold text-white text-lg">CACOD</h2-->

        </div>

        <div class="h-20 content-center block md:flex">
            <div class="flex">
                <div class="h-full pl-5 sm-px-2 md:pl-5 lg:pl-10 content-center">
                <img alt="logo de CACOD" src="./../assets/logo.png" class="w-14 h-14 md:w-16 lg:w-20 md:h-16 lg:h-18 pr-4  rounded-lg object-cover  shadow-lg inline mr-4" >
                <span class="inline md:text-md lg:text-3xl font-bold top-2 relative text-green-900">CACOD</span>
            </div>
            <button type="button" class="block md:hidden focus:outline-none ml-auto mr-5"  @click.prevent="toggleMenu">
                <svg class="w-6 h-6 text-gray-800" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
            </button>
            </div>
            <div class="ml-auto sm-px-2 md:px-2 lg:px-10 md:content-center px-0 w-full md:w-max bg-opacity-95 md:bg-opacity-100 bg-black pt-[30%] md:pt-5 text-center md:bg-white md:relative absolute top-0 left-0   z-[9999] md:z-50   h-screen md:h-full hidden md:block" id="menu">
                <ul class="block md:flex uppercase">
                    <a href="#" @click.prevent="toggleMenu" class="md:hidden text-white font-bold ml-auto block absolute right-10 top-10 capitalize hover:text-green-500">Fermer</a>
                    <li class="mb-4"><router-link to="/" class="md:text-xs lg:text-sm text-white md:text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4">Acceuil</router-link></li>
                    <li class="mb-4">
                        <a href="#" class=" md:text-xs lg:text-sm text-white md:text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4" @click.prevent="toggleDropdown">
                        Qui sommes-nous ?
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        </a>
                        <div v-if="showDropdown" class="absolute bg-white shadow-lg p-4 md:rounded-md z-[9999] w-full md:w-max">
                            <ul class="">
                                <li><a v-scroll-to="'#president'" class="md:text-xs lg:text-sm text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">Mot d'accueil du président</a></li>
                                <hr>
                                <li><a v-scroll-to="'#apropos'" class="md:text-xs lg:text-sm text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">A-propos</a></li>
                                <hr>
                                <li><a v-scroll-to="'#buts'" class="md:text-xs lg:text-sm text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">BUTS, MISSIONS ET FINALITES</a></li>
                                <hr>
                                <li><a v-scroll-to="'#objectifs'" class="md:text-xs lg:text-sm text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">Nos objectifs</a></li>
                                
                                
                            </ul>
                        </div>
                    </li>
                    
                    <li class="mb-4"><a v-scroll-to="'#projets'" class="md:text-xs lg:text-sm text-white md:text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">Nos projets</a></li>
                    <li class="mb-4"><a v-scroll-to="'#contacts'" class="md:text-xs lg:text-sm text-white md:text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">Contacts</a></li>
                    <li class="mb-4" v-if="token==null"><router-link to="/don" class="md:text-xs lg:text-sm text-white bg-green-700 p-3 rounded-full font-bold hover:bg-green-500 md:mr-2 lg:mr-4">Faire un don</router-link></li>
                    <li class="mb-4" v-else>
                        <a href="#" class="md:text-xs lg:text-sm text-white md:text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4" @click.prevent="toggleDropdown_back_office">
                        Back Office
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        </a>
                        <div v-if="showDropdown_back_office" class="absolute w-full md:w-max bg-white shadow-lg p-4 md:rounded-md z-[9999]">
                            <ul class="">
                                <li><a href="#" @click.prevent="goTobackOffice"  class="md:text-xs lg:text-sm text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">Configuration</a></li>
                                <hr>
                                <li><a href="#" @click.prevent="logout"  class="md:text-xs lg:text-sm text-green-900 font-bold hover:text-green-500 md:mr-2 lg:mr-4 hover:cursor-pointer">Déconnexion</a></li>
                                <hr>
                                
                                
                                
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            
        </div>
        <div class="h-8 bg-green-600 px-5 md:px-20 content-center hidden md:block">
            <div class="flex ">
                <svg xmlns="http://www.w3.org/2000/svg" class=" md:h-6 md:w-6 h-4 w-4 phone text-white mr-4 hidden md:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <span class="text-white text-xs md:text-sm content-center">(+243) 97 680 49 96</span>
                
                <div class="border-r border-gray-100 opacity-50 h-6 mx-3"></div>
                <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 h-4 w-4 gmail text-white mr-4 hidden md:inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <span class="text-white text-xs md:text-sm content-center mr-3">info@cacod.net</span>
                <span class="text-white text-xs md:text-sm content-center">espetubale@gmail.com</span>
                <div class="ml-auto flex">
                    
                    <a href="https://facebook.com/cacod.org" class="content-center p-0 text-white hover:text-gray-200 mr-4 hover:text-green-500">
                        <svg class="md:h-6 md:w-6 h-4 w-4 fill-current inline mr-1" viewBox="0 0 24 24">
                            <path d="M12 2.04c-5.5 0-10 4.49-10 10.01 0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10.06 10.06 0 0 0 8.44-9.9c0-5.52-4.5-10.01-10-10.01z"/>
                        </svg> 
                        <span class="text-white text-xs md:text-sm content-center">cacod.org</span>
                    </a>
                    
                    <a href="#" class="text-white hover:text-gray-200 mr-4 hidden">
                        <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/>
                        </svg>
                    </a>

                    <a href="#" class="text-white hover:text-gray-200 mr-4 hidden">
                        <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z"/>
                        </svg>
                    </a>

                    <a href="#" class="text-white hover:text-gray-200 mr-4 hidden">
                        <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/>
                        </svg>
                    </a>
                
                </div>
            </div>

            
           
            
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
       
      showDropdown: false,
      menuActive:false,
      showDropdown_back_office: false,
      token:localStorage.getItem('authToken')
      
    }
  },
  
  methods: {
    toggleMenu() {
        const menuElement = document.getElementById('menu');
        if(menuElement.classList.contains('hidden'))
    {
        menuElement.classList.remove('hidden');
    }
    else
    {
        menuElement.classList.add('hidden');
    }
      
      //this.menuActive = !this.menuActive
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    toggleDropdown_back_office() {
      this.showDropdown_back_office = !this.showDropdown_back_office
    },
    goToLogin()
    {
        this.$router.push('/login')
    },

    goTobackOffice()
    {
        this.$router.push('/back-office')
    },

    logout()
    {
        this.toggleDropdown_back_office()
        axios.get(this.$serverConstant+'logout',{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            
              //L'opération a réussit nous allons supprimer la session de l'utilisateur
              localStorage.removeItem('authToken')
              localStorage.removeItem('username')
              localStorage.removeItem('phone')
              localStorage.removeItem('image')
              localStorage.removeItem('email')
              localStorage.removeItem('user_id')
              localStorage.removeItem('role')
    
            // function_id est l'identifiant de l'Objet qui hérite du User selon son rôle
            localStorage.removeItem('function_id')
             
            this.erreur=response.data.message
            this.$router.push('/')
            
        }).catch(error=>{

// Une erreur dans l'api
 this.erreur=error.message
console.log(error)
})
    }
    
  }
}
</script>