<template>
    <MenuVue/>
    <ActivityVue/>
    <SectionVue/>
    <ProjetConfVue/>
    <TitreConfVue/>
    <UserConfVue/>
    <AccountConfVue/>
    <FooterVue/>
</template>

<script>
import MenuVue from '../MenuVue.vue'
import ActivityVue from './Activity/ActivityVue.vue'
import SectionVue from './Section/SectionVue.vue'
import ProjetConfVue from './Project/ProjetConfVue.vue'
import TitreConfVue from './Titre/TitreConfVue.vue'
import UserConfVue from './Account/UserConfVue.vue'
import AccountConfVue from '../AccountConfVue.vue'
 import FooterVue from '../FooterVue.vue'
export default {
name:'BackOfficePage',
components: {
    MenuVue,
    ActivityVue,
    SectionVue,
    ProjetConfVue,
    TitreConfVue,
    UserConfVue,
    AccountConfVue,
    FooterVue
}
}
</script>

<style>

</style>