<template>
    <div class="w-full md:w-[50%]">
          <h4 class="text-sm font-bold text-black mb-3 pl-5">Ajout d'un nouveau titre</h4>
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full md:max-w-md h-[950px]" >
                  <form @submit.prevent="new_titre">
                      <div class="mb-4">
                          <label class="block text-gray-700 font-bold mb-2" for="title_add_titre">
                          Titre (Max: 50 caractères)
                          </label>
                          <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="title_add_projet"
                          type="text"
                          maxlength="50"
                          required=true
                          placeholder="Entrez le titre"
                          v-model="nom"
                          />
                      </div>

                      <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                      <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
  
                      <div class="flex items-center justify-between">
                          <button
                          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="submit"
                          >
                          Enregistrer
                          </button>
                      </div>
                  </form>
          </div>
      </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
      components:
      {
         
      },
    data() {
        
        return {
          token:localStorage.getItem('authToken'),
          nom:'',
          erreur:'',
          isErreur:false,
          //isLoding:false
        }
      },
    methods:{
      new_titre()
      {
          
      
          
  
          var data={
              nom:this.nom,
  
          }
          
        //  console.log(this.image)
          
          axios.post(this.$serverConstant+'backoffice/titre',data,{
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data'
          }
          }).then(response=>{
              //requete arrivé dans l'api
              // console.log(response)
              if(response.data.status_code==200)
              {
                //var user_data=response.data.data
                this.isErreur=false
                this.erreur="Titre ajouté avec succès"
                this.nom=''
               
                
  
              }
              else
              {
                //L'opération a échoué
                this.isErreur=true
                this.erreur=response.data.message
              }
          }).catch(error=>{
                // Une erreur dans l'api
              this.isErreur=true
             this.erreur=error.response.data.message
          })
      }
    }
  }
  </script>
  
  <style>
  
  </style>