<template>
  <div class="w-full p-O m-0">
    <div class="min-h-screen h-full w-[96%] mx-[2%] my-[3%] px-[2%] py-[3%] rounded  shadow-gray-300 shadow-sm">
        <h4 class="text-3xl text-black font-bold mb-[3%]">Gestion du compte</h4>
        <div class="w-full">
            <div class="w-full mr-[5%]">
                <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full" @submit.prevent="updateProfile">
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="nom">
                        Nom (*)
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="nom"
                        type="text"
                        maxlength="60"
                        required=true
                        placeholder="Entrez votre nom"
                        v-model="nom"
                        
                        />
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="phone">
                        Téléphone (*)
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="phone"
                        type="text"
                        maxlength="60"
                        required=true
                        placeholder="Entrez votre téléphone"
                        v-model="phone"
                        />
                        
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="email">
                        Email 
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="text"
                        maxlength="60"
                        
                        placeholder="Entrez votre  adresse email"
                        v-model="email"
                        />
                        
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="password">
                        Mot de passe
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        placeholder="Entrez votre nouveau mot de passe"
                       type="password"
                       
                        v-model="password"
                        >
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="password_confirmation">
                        Confirmation mot de passe
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password_confirmation"
                        placeholder="Confirmez votre nouveau mot de passe"
                       type="password"
                       
                        v-model="password_confirmation"
                        >
                    </div>

                    <!-- <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="image">
                        Image
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="image"
                        type="file"
                        accept="image/*"
                        @change="handleFileUpload"
                        />
                    </div> -->
                    <p v-if="passwordChanged==true" class="text-blue-500 text-sm truncate w-full mb-4">Votre mot de passe a été changé avec succès</p>


                    <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                    <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                    <p v-if="passwordChanged==true" class="text-blue-500 text-sm truncate w-full mb-4">Votre mot de passe a été changé avec succès</p>

                    <div class="flex items-center justify-between">
                        <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        >
                        Modifier
                        </button>
                    </div>
                </form>
            </div>
            <div class="w-[50%]"> 
                
            </div>
        </div>
  </div>
  </div>
</template>

<script>
    import axios from 'axios'

export default {
  name: 'AccoumptConfVue',
  components: {
    
  },
  data() {
      
      return {
        token:localStorage.getItem('authToken'),
        nom:localStorage.getItem('username'),
        email:localStorage.getItem('email'),
        phone:localStorage.getItem('phone'),
        password:'',
        password_confirmation:'',
        // image:null,
        erreur:'',
        isErreur:false,
        passwordChanged:false
        //isLoding:false
      }
    },
   methods:{
  //   handleFileUpload(event) {
  //     this.image = event.target.files[0]
      
  //   },
  updateProfile()
    {
        
        var data={
            nom:this.nom
            

        }
        if(this.email != localStorage.getItem("email"))
        {
          data.email= this.email
         
        }
        if(this.phone != localStorage.getItem("phone"))
        {
          data.phone=this.phone
        }
        if((this.password !="" && this.password_confirmation !="") && this.password==this.password_confirmation) 
        {
          data.password=this.password
          // data.password_confirmation=this.password_confirmation
          
        }
        if(this.password!=this.password_confirmation)
        {
          this.erreur="Les deux mots de passe ne correspondent pas, le mot de passe n'a pas été modifié"
          this.isErreur=true
        }
        
      
        
        axios.post(this.$serverConstant+'configuration/compte',data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          // 'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            
            if(response.data.status_code==200)
            {
              
             
              var user_data=response.data.data
              localStorage.setItem('username',user_data.nom)
              localStorage.setItem('phone',user_data.phone)
              // localStorage.setItem('image',user_data.user.image)
              localStorage.setItem('email',user_data.email)
              
              this.isErreur=false
              this.erreur="Profil modifié avec succès"
             
              this.password_confirmation=""
              if((this.password !="" && this.password_confirmation !="") && this.password==this.password_confirmation) 
              {
                this.passwordChanged=true
              }
              
             
              

            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
         this.password=""
          this.password_confirmation=""
    }
  }
}
</script>

<style>

</style>