<template>
  
    <div  class="bg-gray-200">
        <div class="bg-blue-900 bg-opacity-70 w-full h-full p-4 rounded-lg drop-shadow-lg">
            <div v-if="erreur!='Aucun contenu disponible'">
                <h3 class="text-2xl md:text-3xl font-bold text-white mb-7 capitalize">{{titre}}</h3>
                <p class="text-white overflow-x-wrap  break-all text-justify leading-relaxed" v-html="contenu"></p>
            </div>
            <div v-else class="text-white text-2xl">
                Aucun but défini
            </div>
            
        </div>
        
    </div>
</template>

<script>
  
  import axios from 'axios'
  export default{
    name: 'ButVue',
    data() {
      return {
        titre: '',
        contenu: '',
        erreur:""
      };
    },
    mounted() {
        this.getBut()
    },
    beforeUnmount() {
     this.getBut()
     
      
    },
    methods: {
     
      getBut()
      {
        
         
        axios.get(this.$serverConstant+'section/but').then(response=>
        {
          
        
          var data=response.data.data;
          var nbResult=Object.keys(data).length
          
          
          if(nbResult>0)
          {
            data=data[0]
            this.titre=data.titre
            this.contenu=data.contenu
            // this.image="http://localhost:8000/storage/"+data.image
            
          }
          else
          {
            
           
            this.erreur="Aucun contenu disponible"  
            //console.log("nullos")
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
          
           this.erreur=error.response.data.message
        })
       
      }
    }
  }
</script>

<style>

</style>