<template>
  <div class="w-full p-O m-0">
    <div class="min-h-screen h-full w-[96%] mx-[2%] my-[3%] px-[2%] py-[3%] rounded  shadow-gray-300 shadow-sm">
        <h4 class="text-3xl text-black font-bold mb-[3%]">Gestion des projets</h4>
        <div class="block md:flex">
           <AddProjectVue/>
           <UpdateProjectVue/>
        </div>
  </div>
  </div>
</template>

<script>
import AddProjectVue from './AddProjectVue.vue';
import UpdateProjectVue from './UpdateProjectVue.vue';

    

export default {
  name: 'ProjetConfVue',
  components: {
    AddProjectVue,
    UpdateProjectVue
  }
}
</script>

<style>

</style>