<template>
<div class="w-full">
    <h4 class="text-sm font-bold text-black mb-3 pl-5">Modification des activités</h4>

    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full h-[950px]"> 
                        
        <form action="" class="mb-10" @submit.prevent="getActivity">
            <label for="search_titre_act" class="block text-gray-700 font-bold mb-2">Titre</label>
            <select id="search_titre_act" @change="getActivity" v-model="search_titre" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-right">
                <option v-for="(item,index) in data" :key=index :value="item.id">
                    {{item.titre}}
                </option>
            </select>
        </form>
            <form v-if="search_titre!=null"  @submit.prevent="change_activity">
              <div class="mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="title_act_mod">
                  Titre (Max: 50 caractères)
                  </label>
                  <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="title_act_mod"
                  type="text"
                  maxlength="50"
                  required=true
                  placeholder="Entrez le titre"
                  v-model="titre"
                  
                  />
              </div>

              <div class="mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="content_act_mod">
                  Contenu (Max: 100 caractères)
                  </label>
                  <p class="shadow appearance-none border rounded w-full py-2 px-3  min-h-[12rem] max-h-[12rem] overflow-y-auto  leading-tight focus:outline-none focus:shadow-outline"
                  id="content_act_mod" v-if="!isEditing" @click="toggleEditMode" v-html="contenu"></p>
                  <div class="shadow appearance-none border rounded w-full py-2 px-3  min-h-[12rem] max-h-[12rem] overflow-y-auto leading-tight focus:outline-none focus:shadow-outline"
                  id="content_act_mod" v-else contenteditable @focusout="updateContent" @blur="toggleEditMode"   v-html="contenu"></div>
                  
                  
              </div>

            <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="message_act_mod">
                Message (Max: 100 caractères)
                </label>
                <textarea
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message_act_mod"
                rows="3"
                placeholder="Entrez le message"
                maxlength="100"
                v-model="message"
                ></textarea>
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="btntext_act_mod">
                Texte du bouton (Max: 15 caractères)
                </label>
                <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="btntext_act_mod"
                placeholder="Entrez le texte du bouton d'action"
                maxlength="15"
                v-model="btntext"
                >
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="image_act_mod">
                Image
                </label>
                <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="image_act_mod"
                type="file"
                accept="image/*"
                @change="handleFileUpload"
                />
            </div>
            <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
            <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                <div class="flex items-center justify-between">
                    <input type="hidden" v-model="id">
                    <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    >
                    Modifier
                    </button>

                    <button v-if="deleted==0"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment supprimer cette activité ?')"
                    >
                    Supprimer
                    </button>
                    <button v-else
                    class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment débloquer cette activité ?')"
                    >
                    Débloquer
                    </button>
                </div>
            </form>
            <div v-else class="content-center w-full h-full text-center">
                Aucune activité trouvée
            </div>
        
                    
                
            
    </div>
</div>
</template>

<script>

import axios from 'axios'
export default {
    components:
    {
        
    },
    data() {
      
      return {
        token:localStorage.getItem('authToken'),
        data:null,
        search_titre:null,
        found_activity:null,
        deleted:'',
        titre:'',
        contenu:'',
        message:'',
        btntext:'',
        image:null,
        erreur:'',
        isErreur:false,
        isEditing:false
        //isLoding:false
      }
    },
    beforeUnmount()
    {
        this.getActivities()
        
    },
    mounted()
    {
        this.getActivities()
        
    },
    methods:{
    handleFileUpload(event) {
      this.image = event.target.files[0]
      
    },
    toggleEditMode() {
      this.isEditing = !this.isEditing
    },
    updateContent(event) {
      this.contenu = event.target.innerHTML
    },
    getActivities()
    {
        axios.get(this.$serverConstant+'backoffice/activity',{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            this.data=response.data.data
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    getActivity()
    {
        
        axios.get(this.$serverConstant+'backoffice/activity/'+this.search_titre,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            
            //this.found_activity
            this.found_activity=response.data.data
            this.deleted=this.found_activity.deleted
            this.titre=this.found_activity.titre,
            this.contenu=this.found_activity.contenu,
            this.message=this.found_activity.message,
            this.btntext=this.found_activity.btntext
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    change_activity()
    {
        
        var data={
            titre:this.titre,
            contenu:document.getElementById('content_act_mod').innerHTML,
            message:this.message,
            btntext:this.btntext,
            image:this.image

        }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'backoffice/activity/'+this.search_titre,data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            if(response.data.status_code==200)
            {
              //var user_data=response.data.data
              this.isErreur=false
              this.erreur="Activité modifiée avec succès"
              
             
              

            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
    },

    confirmation(message)
    {
      
        if(confirm(message))
        {
           this.blockage(this.found_activity.id)
        }
        
    },
    blockage(id)
    {
        axios.get(this.$serverConstant+'backoffice/activity/delete/'+id,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
    }).then(response=>{
     
            //requete arrivé dans l'api
            
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              this.deleted=this.found_activity.deleted
              if(this.found_activity.deleted==0)
              {
                this.erreur="activité supprimée avec succès"
              }
              else
              {
                this.erreur="activité débloquée avec succès"
              }
              this.getActivity()
              
              
            
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })}
    
}
      

}
</script>

<style>

</style>