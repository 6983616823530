<template>
     <div class="w-full md:w-[50%]">
        <h4 class="text-sm font-bold text-black mb-3 pl-5">Ajout d'une nouvelle section</h4>
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full md:max-w-md h-[950px]" >
            <form  @submit.prevent="new_section">
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="titre_add_section">
                    Titre (Max: 50 caractères)
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="titre_add_section"
                    type="text"
                    maxlength="50"
                    required=true
                    placeholder="Entrez le titre"
                    v-model="titre"
                    />
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="content_add_section">
                    Contenu
                    </label>
                    <div ref="editorElement">
                        <TiptapVue/>
                    </div>
                    
                </div>


                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="type_add_section">
                    Type de section
                    </label>
                    <select 
                    class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center
    bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]"
                    id="type_add_section"
                    required=true
                    v-model="type"
                    >
                    <option value="président">Mot d'accueil du président</option>
                    <option value="apropos">Qui sommes-nous</option>
                    <option value="but">But</option>
                    <option value="mission">Mission</option>
                    <option value="finalités">Finalités</option>
                    <option value="objectifs">Objectifs</option>
                    </select>
                </div>

                <div class="mb-4" v-if="type!='but' && type!='finalités' && type!='mission' && type!=''">
                    <label class="block text-gray-700 font-bold mb-2" for="image_add_section">
                    Image
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="image_add_section"
                    type="file"
                    required
                    accept="image/*"
                    @change="handleFileUpload"
                    />
                </div>
                <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                <div class="flex items-center justify-between">
                    <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    >
                    Enregistrer
                    </button>
                </div>
            </form>
        </div>
    </div>
            
</template>

<script>
import TiptapVue from '../../TiptapVue.vue'
import axios from 'axios'
export default {
    components:{
        TiptapVue,
    }
    ,
  data() {
      
      return {
        token:localStorage.getItem('authToken'),
        titre:'',
        contenu:'',
        type:'',
        image:null,
        erreur:'',
        isErreur:false,
        //isLoding:false
      }
    },
  methods:{
    handleFileUpload(event) {
      this.image = event.target.files[0]
      
    },
    new_section()
    {
        
    
        

        var data={
            titre:this.titre,
            contenu:document.querySelectorAll(".div_contenu")[1].querySelector('p').innerHTML,
            type:this.type,
            image:this.image

        }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'backoffice/section',data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            if(response.data.status_code==200)
            {
              //var user_data=response.data.data
              this.isErreur=false
              this.erreur="Section ajoutée avec succès"
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
    }
  }
}
</script>

<style>

</style>