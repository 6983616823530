<template>
   <MenuVue/>
   <div class="h-max w-full mb-[10%] md:mb-[0%]">
      <div v-if="erreur!='Aucun contenu disponible'" class="h-[50%] w-full banner"  alt="Photo du président" :style="{ backgroundImage: `url('${image}')` }">
        <div class="bg-black bg-opacity-30 h-full w-full py-[2%] content-center">
          <p class="text-5xl md:text-7xl text-white font-bold px-[7%] w-[40%] flex">Mot d'accueil du président</p>
        </div>
        
      </div>

    </div>

    <div class="h-max w-full  px-[5%] py-[7%] bg-white bg-opacity-50">
        <div class="w-full" v-if="erreur!='Aucun contenu disponible'">
            
            <div class=" w-full w-full">
                <h4 class="text-5xl font-bold text-green-900 mb-7">{{titre}}</h4>
                <a :href="image" target="blank" class="w-full mr-[7%]">
                  <img class="object-cover  rounded-lg w-full " :src="image" alt="Photo du président">
                </a>
                
                <p class=" mb-5 overflow-wrap break-all text-justify leading-relaxed" v-html="contenu"></p>
                
                                
            </div>
        </div>
        <div class="text-3xl content-center text-center">
          {{ erreur }}
        </div>
    </div>
    <FooterVue/>
</template>

<script>
  import MenuVue from '../MenuVue.vue'
  import FooterVue from '../FooterVue.vue'
  import axios from 'axios'
  export default{
    name: 'PresidentPageVue',
    components:{
      MenuVue,
      FooterVue
      
      
    },
    data() {
      return {
        titre: '',
        contenu: '',
        image:'',
        erreur:""
      };
    },
    mounted() {
        this.getPresident()
    },
    beforeUnmount() {
     this.getPresident()
     
      
    },
    methods: {
     
      getPresident()
      {
        
         
        axios.get(this.$serverConstant+'section/président').then(response=>
        {
          
        
          var data=response.data.data;
          var nbResult=Object.keys(data).length
          
          
          if(nbResult>0)
          {
            data=data[0]
            this.titre=data.titre
            this.contenu=data.contenu
            this.image=this.$storageConstant+data.image
            
          }
          else
          {
            
           
            this.erreur="Aucun contenu disponible"  
            
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
          
           this.erreur=error.response.data.message
        })
       
      }
    }
  }
</script>

<style>
.banner
{
 
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>