<template>
    <div v-if="erreur!='Aucun contenu disponible'" class="h-max w-full my-[7%] px-[7%] py-[5%] bg-white bg-opacity-50" id="objectifs">
          <div class="md:flex h-full">
            <h4 class="text-6xl md:text-7xl md:hidden lg:text-8xl text-green-900 ml-auto mt-[10%] font-bold mb-7">{{titre}}</h4>                    

            <img class=" w-full md:w-[20%]    md:w-80 md:h-80 rounded-lg mr-[7%] object-cover object-top" :src="image">
            <h4 class="hidden md:block text-6xl md:text-7xl lg:text-8xl text-green-900 ml-auto mt-[10%] font-bold mb-7">{{titre}}</h4>                    
          </div>
          <p class="px-0 my-5 text-justify" v-html="contenu"></p>
      </div>
  </template>
  
  <script>
  
  import axios from 'axios'
  export default{
    name: 'ObjectifVue',
    data() {
      return {
        titre: '',
        contenu: '',
        erreur:""
      };
    },
    mounted() {
        this.getObjectif()
    },
    beforeUnmount() {
     this.getObjectif()
     
      
    },
    methods: {
     
      getObjectif()
      {
        
         
        axios.get(this.$serverConstant+'section/objectifs').then(response=>
        {
          
        
          var data=response.data.data;
          var nbResult=Object.keys(data).length
          
          
          if(nbResult>0)
          {
            data=data[0]
            this.titre=data.titre
            this.contenu=data.contenu
            this.image=this.$storageConstant+data.image
            
          }
          else
          {
            
           
            this.erreur="Aucun contenu disponible"  
            //console.log("nullos")
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
          
           this.erreur=error.response.data.message
        })
       
      }
    }
  }
  </script>
  
  <style>
    .objectif
    {
      /* background-image: url("./../assets/objectif.png"); */
      background-position: top center;
    }
  </style>