<template>
    <div v-if="erreur!='Aucun contenu disponible'" class="h-max min-h-screen w-full content-center mt-[7%] px-[7%] py-[10%] bg-white bg-opacity-50" id="president">
        <div class="md:flex">
          <h4 class="text-3xl md:hidden md:text-5xl font-bold text-green-900 mt-[7%] mb-7">{{titre}}</h4>
            <img class="object-cover object-top w-full md:w-[20%]  rounded-lg mr-[7%]" :src="image" alt="Photo du président">
            <div class="w-full md:w-[60%] md:flex md:flex-col font-medium font-serif">
                <h4 class="hidden md:block text-3xl md:text-5xl font-bold text-green-900 mt-[7%] mb-7">{{titre}}</h4>
                
                <p class="mb-10 mt-10 text-base line-clamp-5 overflow-wrap break-all text-justify leading-relaxed" v-html="contenu">
                </p>
                <router-link to="/president" class="bg-green-800 text-xs md:text-md hover:bg-green-500  px-4 py-4 rounded-full text-white ml-auto">En savoir plus</router-link>
            </div>
        </div>
    </div>
    
</template>


<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        titre: '',
        contenu: '',
        image:'',
        erreur:""
      };
    },
    mounted() {
        this.getPresident()
    },
    beforeUnmount() {
     this.getPresident()
     
      
    },
    methods: {
     
      getPresident()
      {
        
         
        axios.get(this.$serverConstant+'section/président').then(response=>
        {
          
         
          var data=response.data.data;
          var nbResult=Object.keys(data).length
          
          
          if(nbResult>0)
          {
            data=data[0]
            this.titre=data.titre
            this.contenu=data.contenu
            this.image=this.$storageConstant+data.image
            
          }
          else
          {
            
           
            this.erreur="Aucun contenu disponible"  
            //console.log("nullos")
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
          
           this.erreur=error.response.data.message
        })
       
      }
    }
  };
  </script>
