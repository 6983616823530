<template>
  <div>
    <section v-if="editor" class="buttons container flex items-center flex-wrap gap-x-4 border-x border-t text-gray-700 p-4 outline-none">
      <div class="control-group">
      <div class="button-group">
        <button type="button" class="bg-blue-600 text-white rounded text-sm font-bold p-1 mr-2 mb-2" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          Gras
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm italic p-1 mr-2 mb-2" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
          Italic
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm  p-1 mr-2 mb-2" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
          Souligner
        </button>
        <!-- <button type="button" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          Strike
        </button> -->
        <!-- <button type="button" @click="editor.chain().focus().toggleCode().run()" :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
          Code
        </button> -->
        <!-- <button type="button" @click="editor.chain().focus().unsetAllMarks().run()">
          Clear marks
        </button> -->
        <!-- <button type="button" @click="editor.chain().focus().clearNodes().run()">
          Clear nodes
        </button> -->
        <!-- <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
          Paragraphe
        </button> -->
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          Titre 1
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          Titre 2
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
          Titre 3
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
          Titre 4
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
          Titre 5
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
          Titre 6
        </button>
        <!-- <button class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
          List à puce
        </button>
        <button class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
          Liste
        </button> -->
        <!-- <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
          Code block
        </button>
        <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
          Blockquote
        </button> -->
        <!-- <button @click="editor.chain().focus().setHorizontalRule().run()">
          Horizontal rule
        </button> -->
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setHardBreak().run()">
          Enter
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
          Annuler
        </button>
        <input 
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
          :value="editor.getAttributes('textStyle').color"
        >
        <button type="button" class="bg-purple-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setColor('#958DF1').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#958DF1' })}">
          Move
        </button>
        <button type="button" class="bg-red-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}">
          Rouge
        </button>
        <button type="button" class="bg-orange-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setColor('#FBBC88').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#FBBC88' })}">
          Orange
        </button>
        <button type="button" class="bg-yellow-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setColor('#FAF594').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#FAF594' })}">
          Jaune
        </button>
        <button type="button" class="bg-blue-600 text-white rounded text-sm p-1 mr-2 mb-2" @click="editor.chain().focus().setColor('#70CFF8').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#70CFF8' })}">
          Bleue
        </button>
        
      </div>
    </div>
   
    </section>
 
    <editor-content :editor="editor" class="div_contenu"/>
    
  </div>
  
</template>

<script setup>
  //import { Head, Link } from '@inertiajs/vue3'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
  import { useEditor, EditorContent } from '@tiptap/vue-3'
  import StarterKit from '@tiptap/starter-kit'
  import Underline from '@tiptap/extension-underline'

  const editor = useEditor({
    editorProps: {
      attributes: {
                    class: "shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline min-h-[12rem] max-h-[12rem] overflow-y-auto",
                    

                  }

    },
    content: '<p class="text-white  font-bold">Ecrivez votre contenu ici</p>',
    
   
    extensions: [StarterKit,Underline, ListItem,TextStyle,Color],
  })
//   ListItem.configure({
//   HTMLAttributes: {
//     class: 'my-custom-class',
//   },
// })

</script>

<style lang="scss">

/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    color:black;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }
}
</style> 