<template>
    <div class="w-full h-max py-[4%] content-center bg-yellow-100 bg-opacity-30" id="projets">
      <div class="h-[50%] mx-[5%]">
        <h1 class="text-5xl font-bold text-green-900 mb-[3%]">Nos projets</h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div
            v-for="(item, index) in projetItems"
            :key="index"
            class="bg-white bg-opacity-100 w-full p-4 rounded-lg drop-shadow-lg"
            @click="showFullImage(item.images[item.images.length-1].path)"
          >
            <h3 class="text-3xl font-bold text-black h-14 mb-2">{{ item.titre }}</h3>
            <div class="flex justify-end">
              <span v-if="item.statut=='en cours'" class="bg-blue-500 text-sm p-2 text-white rounded-full mb-3">Projet {{ item.statut }}</span>
              <span v-if="item.statut=='réalisé'" class="bg-green-500 text-sm p-2 text-white rounded-full mb-3">Projet {{ item.statut }}</span>
              <span v-if="item.statut=='à venir'" class="bg-gray-500 text-sm p-2 text-white rounded-full mb-3">Projet {{ item.statut }}</span>
            </div>
            
            <img
              class="object-cover object-top w-full h-60 my-[5%] cursor-pointer"
              :src="this.$storageConstant+item.images[item.images.length-1].path"
            />
            <p
              class="text-sm text-black text-base overflow-y-auto h-60 overflow-x-wrap break-all text-justify leading-relaxed"
              v-html="item.contenu"
            ></p>
          </div>
        </div>
        <div v-if="showImageModal" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50">
          
          <img :src="this.$storageConstant+selectedImagePath" class="max-w-[90%] max-h-[90%] object-contain" />
          <button
            class="absolute top-4 right-4 bg-white text-black px-4 py-2 rounded-md"
            @click="closeFullImage"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    data() {
      return {
       
        projetItems: this.getProjets(),
        erreur:"",
        showImageModal: false,
      selectedImagePath: '',
      };
    },
    mounted() {
      this.getProjets();
    },
    beforeUnmount() {
      
      this.getProjets()
    },
    methods: {

    getProjets()
      {
        
        axios.get(this.$serverConstant+'projet').then(response=>
        {
          
          var projet_data=response.data.data;
          var nbResult=Object.keys(projet_data).length
          
          
          if(nbResult>0)
          {
            
            this.projetItems=projet_data
            
            
          }
          else
          {
            
           
            this.erreur="Aucun contenu disponible" 
            //console.log("nullos")
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
           
           this.erreur=error.response.data.message
        })
       
      },
      showFullImage(imagePath) {
      this.showImageModal = true;
      this.selectedImagePath = imagePath;
    },
    closeFullImage() {
      this.showImageModal = false;
      this.selectedImagePath = '';
    },
    }
  };
  </script>
  
  <style>
  
  </style>