<template>
    <!--img alt="Vue logo" src="./assets/logo.png"-->
    <!--HelloWorld msg="Welcome to Your Vue.js App"/--->
    <div id="app">
      <MenuVue/>
      <CarouselVue/>
      <PresidVue/>
      <AproposVue/> 
      <ButSectionVue/>
      <ObjectifVue/>
      <ProjetVue/>
      <FooterVue/>

      <button v-scroll-to="'#mainMenu'" class="fixed bottom-4 right-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full shadow-lg flex items-center">
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </button>
    </div>
     
    
  </template>
  
  <script>
  /*import HelloWorld from './components/HelloWorld.vue'
  
  export default {
    name: 'App',
    components: {
      HelloWorld
    }
  }*/
  
  import MenuVue from '../MenuVue.vue'
  import CarouselVue from '../CarouselVue.vue'
  import PresidVue from './Section/PresidVue.vue'
  import AproposVue from './Section/AproposVue.vue'
  import ButSectionVue from './Section/ButSectionVue.vue'
  import ObjectifVue from './Section/ObjectifVue.vue'
  import ProjetVue from './Project/ProjetVue.vue'
  import FooterVue from '../FooterVue.vue'
  
  
  export default{
    name: 'HomeVue',
    components:{
      MenuVue,
      CarouselVue,
      PresidVue,
      AproposVue,
      ButSectionVue,
      ObjectifVue,
      ProjetVue,
      FooterVue
      
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0px;
    padding: 0px;
    
  }
  body
  {
    background-color: #f7fafc;;
  }
  </style>
  