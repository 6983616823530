<template>
    <div class="w-full">
        <h4 class="text-sm font-bold text-black mb-3 pl-5">Modification des sections</h4>

        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full h-[950px]"> 
            <form action="" class="mb-10" @submit.prevent="getSection">
                <label for="search_titre_sect" class="block text-gray-700 font-bold mb-2">Titre</label>
                <select id="search_titre_sect" @change="getSection" v-model="search_titre_section" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-right">
                    <option v-for="(item,index) in data" :key=index :value="item.id">
                        {{item.titre}}
                    </option>
                </select>
            </form>                 
            <form v-if="search_titre_section!=null"   @submit.prevent="changeSection">
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="titre_change_section">
                    Titre (Max: 50 caractères)
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="titre_change_section"
                    type="text"
                    maxlength="50"
                    required=true
                    placeholder="Entrez le titre"
                    v-model="titre"
                    
                    />
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="content_change_section">
                    Contenu (Max: 100 caractères)
                    </label>
                    <p class="shadow appearance-none border rounded w-full py-2 px-3  min-h-[12rem] max-h-[12rem] overflow-y-auto  leading-tight focus:outline-none focus:shadow-outline"
                    id="content_change_section" v-if="!isEditing" @click="toggleEditMode" v-html="contenu"></p>
                    <div class="shadow appearance-none border rounded w-full py-2 px-3  min-h-[12rem] max-h-[12rem] overflow-y-auto  leading-tight focus:outline-none focus:shadow-outline"
                    id="content_change_section" v-else contenteditable @focusout="updateContent" @blur="toggleEditMode"   v-html="contenu"></div>
                    
                
                </div>


                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="type_change_section">
                    Type de section
                    </label>
                    <select 
                    class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center
    bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]"
                    id="type_change_section"
                    required=true
                    @change="type = $event.target.value"
                    v-model="type"
                    :disabled="true"
                   
                    >
                    <option :value="type">{{ type }}</option>
                    
                    </select>
                </div>

                <div class="mb-4" v-if="type!='but' && type!='finalités' && type!='mission' && type!=''">
                    <label class="block text-gray-700 font-bold mb-2" for="image_change_section">
                    Image
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="image_change_section"
                    type="file"
                    accept="image/*"
                    @change="handleFileUpload"
                    />
                </div>
                <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                <div class="flex items-center justify-between">
                    <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    >
                    Modifier
                    </button>

                    <button v-if="deleted==0"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment supprimer cette section ?')"
                    >
                    Supprimer
                    </button>
                    <button v-else
                    class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment débloquer cette section ?')"
                    >
                    Débloquer
                    </button>
                </div>
            </form>
            <div v-else class="content-center w-full h-full text-center">
                Aucune section trouvée
            </div>
        </div>
                
    </div>
</template>

<script>

import axios from 'axios'
export default {
    components:
    {
        
    },
    data() {
      
      return {
        token:localStorage.getItem('authToken'),
        data:null,
        titre:'',
        search_titre_section:null,
        found_section:null,
        deleted:'',
        contenu:'',
        type:'',
        image:null,
        erreur:'',
        isErreur:false,
        isEditing:false,
        //isLoding:false
      }
    },
    beforeUnmount()
    {
        this.getSections()
        
    },
    mounted()
    {
        this.getSections()
        
    },
    methods:{
    handleFileUpload(event) {
      this.image = event.target.files[0]
      
    },
    toggleEditMode() {
      this.isEditing = !this.isEditing
    },
    updateContent(event) {
      this.contenu = event.target.innerHTML
    },
    getSections()
    {
        axios.get(this.$serverConstant+'backoffice/section',{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            this.data=response.data.data
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    getSection()
    {
        
        axios.get(this.$serverConstant+'backoffice/section/'+this.search_titre_section,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            
            //this.found_section
            this.found_section=response.data.data
            this.titre=this.found_section.titre,
            this.deleted=this.found_section.deleted,
            this.contenu=this.found_section.contenu,
            this.type=this.found_section.type
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    changeSection()
    {
        
        var data={
             titre:this.titre,
            contenu:document.getElementById('content_change_section').innerHTML,
            type:this.type,
    
        }

        if(this.image!=null)
        {
            data.image=this.image
        }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'backoffice/section/'+this.search_titre_section,data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              this.erreur="section modifiée avec succès"
              
             
              

            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
    }
    ,
confirmation(message)
    {
        if(confirm(message))
        {
           this.blockage(this.found_section.id)
        }
    },
    blockage(id)
    {
        axios.get(this.$serverConstant+'backoffice/section/delete/'+id,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
    }).then(response=>{
     
            //requete arrivé dans l'api
            
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              this.deleted=this.found_section.deleted
              if(this.found_section.deleted==0)
              {
                this.erreur="section supprimée avec succès"
              }
              else
              {
                this.erreur="section débloquée avec succès"
              }
              this.getSection()
              
              
            
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })}
    
}

    

      

}
</script>


<style>

</style>