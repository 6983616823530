<template>
  <div class="w-full">
        <h4 class="text-sm font-bold text-black mb-3 pl-5">Modification d'un projet</h4>

        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full h-[950px]"> 
                            
            <form action="" class="mb-10" @submit.prevent="getProjet">
                <label for="search_titre_proj" class="block text-gray-700 font-bold mb-2">Titre</label>
                <select id="search_titre_proj" @change="getProjet" v-model="search_titre_projet" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-right">
                    <option v-for="(item,index) in data" :key=index :value="item.id">
                        {{item.titre}}
                    </option>
                </select>
            </form>
            <form  v-if="search_titre_projet!=null" @submit.prevent="change_projet">
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="title_change_proj">
                        Titre (Max: 50 caractères)
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="title_change_proj"
                        type="text"
                        maxlength="50"
                        required=true
                        placeholder="Entrez le titre"
                        v-model="titre"
                        />
                    </div>

                    <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="statut_proj">
                    Statut du projet
                    </label>
                    <select 
                    class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center
    bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]"
                    id="statut_proj"
                    required=true
                    @change="statut = $event.target.value"
                    v-model="statut"
                    >
                    <option value="en cours">En cours</option>
                    <option value="réalisé">Réalisé</option>
                    <option value="à venir">A venir</option>
                    </select>
                </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="content_change_proj">
                        Contenu (Max: 100 caractères)
                        </label>
                        <p class="shadow appearance-none border rounded w-full py-2 px-3  min-h-[12rem] max-h-[12rem] overflow-y-auto  leading-tight focus:outline-none focus:shadow-outline"
                        id="content_change_proj" v-if="!isEditing" @click="toggleEditMode" v-html="contenu"></p>
                        <div class="shadow appearance-none border rounded w-full py-2 px-3  min-h-[12rem] max-h-[12rem] overflow-y-auto  leading-tight focus:outline-none focus:shadow-outline"
                        id="content_change_proj" v-else contenteditable @focusout="updateContent" @blur="toggleEditMode"   v-html="contenu"></div>
                        
                
                </div>


                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="image_change_proj">
                        Image
                        </label>
                        <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="image_change_proj"
                        type="file"
                        accept="image/*"
                        @change="handleFileUpload"
                        />
                    </div>
                    <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                    <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                    <div class="flex items-center justify-between">
                        <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        >
                        Modifier
                        </button>
                        <button v-if="deleted==0"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment supprimer ce projet ?')"
                    >
                    Supprimer
                    </button>
                    <button v-else
                    class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" @click="confirmation('Voulez vous vraiment débloquer ce projet ?')"
                    >
                    Débloquer
                    </button>
                    </div>
                </form>

        </div>
    </div>
</template>

<script>

import axios from 'axios'
export default {
    components:
    {
        
    },
    data() {
      
      return {
        token:localStorage.getItem('authToken'),
        data:null,
        deleted:'',
        titre:'',
        statut:'',
        search_titre_projet:null,
        found_projet:null,
        contenu:'',
        image:null,
        erreur:'',
        isErreur:false,
        isEditing:false,
        //isLoding:false
      }
    },
    beforeUnmount()
    {
        this.getProjets()
        
    },
    mounted()
    {
        this.getProjets()
        
    },
    methods:{
    handleFileUpload(event) {
      this.image = event.target.files[0]
      
    },
    toggleEditMode() {
      this.isEditing = !this.isEditing
    },
    updateContent(event) {
      this.contenu = event.target.innerHTML
    },
    getProjets()
    {
        axios.get(this.$serverConstant+'backoffice/projet',{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            this.data=response.data.data
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    getProjet()
    {
        
        axios.get(this.$serverConstant+'backoffice/projet/'+this.search_titre_projet,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
        }).then(response=>{
            
            
            this.found_projet=response.data.data
            this.titre=this.found_projet.titre,
            this.deleted=this.found_projet.deleted
            this.contenu=this.found_projet.contenu,
            this.statut=this.found_projet.statut
          
      }).catch(error=>{

this.erreur=error.message
console.log(error)
})
    },
    change_projet()
    {
        
        var data={
            titre:this.titre,
            contenu:document.getElementById('content_change_proj').innerHTML,
            statut:this.statut,
            type:this.type,
    
        }

        if(this.image!=null)
        {
            data.image=this.image
        }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'backoffice/projet/'+this.search_titre_projet,data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              this.erreur="projet modifié avec succès"
            
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })
        
    },
    confirmation(message)
    {
        if(confirm(message))
        {
           this.blockage(this.found_projet.id)
        }
    },
    blockage(id)
    {
        axios.get(this.$serverConstant+'backoffice/projet/delete/'+id,{
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
    }).then(response=>{
     
            //requete arrivé dans l'api
            
            if(response.data.status_code==200)
            {
             
              this.isErreur=false
              this.deleted=this.found_projet.deleted
              if(this.found_projet.deleted==0)
              {
                this.erreur="projet supprimé avec succès"
              }
              else
              {
                this.erreur="projet débloqué avec succès"
              }
              this.getProjet()
              
              
            
            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
        })}

    
}
      

}
</script>

<style>

</style>