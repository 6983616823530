<template>
    <div class="w-full">
          <h4 class="text-sm font-bold text-black mb-3 pl-5">Modification d'un titre</h4>
  
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full h-[950px]"> 
                              
              <form action="" class="mb-10" @submit.prevent="getTitre">
                  <label for="search_titre" class="block text-gray-700 font-bold mb-2">Titre</label>
                  <select id="search_titre" @change="getTitre" v-model="search_titre" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-right">
                      <option v-for="(item,index) in data" :key=index :value="item.id">
                          {{item.nom}}
                      </option>
                  </select>
              </form>
              <form  v-if="search_titre!=null" @submit.prevent="change_titre">
                      <div class="mb-4">
                          <label class="block text-gray-700 font-bold mb-2" for="title_change_proj">
                          Titre (Max: 50 caractères)
                          </label>
                          <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="title_change_titre"
                          type="text"
                          maxlength="50"
                          required=true
                          placeholder="Entrez le titre"
                          v-model="nom"
                          />
                      </div>
                      <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                      <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
  
                      <div class="flex items-center justify-between">
                          <button
                          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="submit"
                          >
                          Modifier
                          </button>
                          <button v-if="deleted==0"
                      class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button" @click="confirmation('Voulez vous vraiment supprimer ce titre ?')"
                      >
                      Supprimer
                      </button>
                      <button v-else
                      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button" @click="confirmation('Voulez vous vraiment débloquer ce titre ?')"
                      >
                      Débloquer
                      </button>
                      </div>
                  </form>
  
          </div>
      </div>
  </template>
  
  <script>
  
  import axios from 'axios'
  export default {
      components:
      {
          
      },
      data() {
        
        return {
          token:localStorage.getItem('authToken'),
          data:null,
          deleted:'',
          nom:'',
          search_titre:null,
          found_titre:null,
          erreur:'',
          isErreur:false,
          //isLoding:false
        }
      },
      beforeUnmount()
      {
          this.getTitres()
          
      },
      mounted()
      {
          this.getTitres()
          
      },
      methods:{
      getTitres()
      {
          axios.get(this.$serverConstant+'backoffice/titre',{
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
          }).then(response=>{
              this.data=response.data.data
            
        }).catch(error=>{
  
  this.erreur=error.message
  console.log(error)
  })
      },
      getTitre()
      {
          
          axios.get(this.$serverConstant+'backoffice/titre/'+this.search_titre,{
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
          }).then(response=>{
              
              
              this.found_titre=response.data.data
              this.nom=this.found_titre.titre,
              this.deleted=this.found_titre.deleted
              this.contenu=this.found_titre.contenu,
              this.statut=this.found_titre.statut
            
        }).catch(error=>{
  
  this.erreur=error.message
  console.log(error)
  })
      },
      change_titre()
      {
          
          var data={
              nom:this.nom,
          }
        
          axios.post(this.$serverConstant+'backoffice/titre/'+this.search_titre,data,{
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data'
          }
          }).then(response=>{
              //requete arrivé dans l'api
              // console.log(response)
              if(response.data.status_code==200)
              {
               
                this.isErreur=false
                this.erreur="titre modifié avec succès"
              
              }
              else
              {
                //L'opération a échoué
                this.isErreur=true
                this.erreur=response.data.message
              }
          }).catch(error=>{
                // Une erreur dans l'api
              this.isErreur=true
             this.erreur=error.response.data.message
          })
          
      },
      confirmation(message)
      {
          if(confirm(message))
          {
             this.blockage(this.found_titre.id)
          }
      },
      blockage(id)
      {
          axios.get(this.$serverConstant+'backoffice/titre/delete/'+id,{
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
      }).then(response=>{
       
              //requete arrivé dans l'api
              
              if(response.data.status_code==200)
              {
               
                this.isErreur=false
                this.deleted=this.found_titre.deleted
                if(this.found_titre.deleted==0)
                {
                  this.erreur="titre supprimé avec succès"
                }
                else
                {
                  this.erreur="titre débloqué avec succès"
                }
                this.getTitre()
                
                
              
              }
              else
              {
                //L'opération a échoué
                this.isErreur=true
                this.erreur=response.data.message
              }
          }).catch(error=>{
                // Une erreur dans l'api
              this.isErreur=true
             this.erreur=error.response.data.message
          })}
  
      
  }
        
  
  }
  </script>
  
  <style>
  
  </style>