<template>
    <div class="w-full md:w-[50%]">
        <h4 class="text-sm font-bold text-black mb-3 pl-5">Ajout d'un nouvel utilisateur</h4>
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full md:max-w-md h-[950px]" >
            <form  @submit.prevent="register">
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="nom_new_user">
                    Nom (*)
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="nom_new_user"
                    type="text"
                    maxlength="60"
                    required=true
                    placeholder="Entrez le nom de l'utilisateur"
                    v-model="nom"
                    />
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="phone_new_user">
                    Téléphone (*)
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone_new_user"
                    type="phone"
                    maxlength="13"
                    required=true
                    placeholder="Entrez le téléphone de l'utilisateur (ex : +243812731458)"
                    v-model="phone"
                    />
                    
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="email_new_user">
                    Email
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email_new_user"
                    type="email"
                    placeholder="Entrez l'adresse email de l'utilisateur"
                    v-model="email"
                    />
                    
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="role_new_user">
                    Rôle
                    </label>
                    <Select @change="role = $event.target.value" class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]" id="role_new_user"
                    
                    required="true"
                    >
                    
                    <option value="sadmin">Super admin</option>
                    <option value="admin">Admin</option>
                    </Select>
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="titre_new_user">
                    Titre
                    </label>
                    <Select @change="titre_id = $event.target.value" class="shadow appearance-none w-full py-2 pr-3 pl-10 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-no-repeat bg-right-center bg-[url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%208l4%204%204-4%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E')]" id="titre_new_user"
                    
                    required="true"
                    >
                    <option value=""></option>
                    <option v-for="(item,index) in titres" :key=index :value="item.id" >{{item.nom}}</option>
                    </Select>
                </div>

                <!-- <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="image">
                    Image
                    </label>
                    <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="image"
                    type="file"
                    accept="image/*"
                    @change="handleFileUpload"
                    />
                </div> -->
                <p v-if="isErreur==true" class="text-red-500 text-sm truncate w-full mb-4">{{ erreur }}</p>
                <p v-if="isErreur==false" class="text-green-500 text-sm truncate w-full mb-4">{{ erreur }}</p>

                <div class="flex items-center justify-between">
                    <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    >
                    Enregistrer
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AddUserVue',
  components: {
    
  },
  data() {
      
      return {
        token:localStorage.getItem('authToken'),
        user_role:localStorage.getItem('role'),
        nom:'',
        phone:'',
        email:'',
        role:'sadmin',
        titre_id:'',
        titres:null,
        // image:null,
        erreur:'',
        isErreur:false,
        //isLoding:false
      }
    },
    beforeMount(){
      this.getTitres()
    },
  methods:{
    // handleFileUpload(event) {
    //   this.image = event.target.files[0]
      
    // },

    getTitres()
      {
          axios.get(this.$serverConstant+'backoffice/titres',{
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
          }).then(response=>{
              this.titres=response.data.data
            
        }).catch(error=>{
  
  this.erreur=error.message
  console.log(error)
  })
      },
    register()
    {
      
        
        var data={
          nom:this.nom,
            phone:this.phone,
            email:this.email,
            role:this.role,
            titre_id:this.titre_id,
            erreur:this.erreur,
            isErreur:this.isErreur

        }
        
      //  console.log(this.image)
        
        axios.post(this.$serverConstant+'register/compte',data,{
        headers: {
          'Authorization': `Bearer ${this.token}`,
          // 'Content-Type': 'multipart/form-data'
        }
        }).then(response=>{
            //requete arrivé dans l'api
            // console.log(response)
            if(response.data.status_code==200)
            {
              //var user_data=response.data.data
              this.isErreur=false
              this.erreur="Utilisateur ajouté avec succès"
              this.nom=''
              this.phone=''
              this.email='' 

            }
            else
            {
              //L'opération a échoué
              this.isErreur=true
              this.erreur=response.data.message
            }
        }).catch(error=>{
              // Une erreur dans l'api
            this.isErreur=true
           this.erreur=error.response.data.message
           console.log(this.erreur)
        })
    }
  }
}
</script>

<style>

</style>