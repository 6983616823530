<template>
    <div class="w-full p-O m-0" v-if="user_role=='sadmin'">
      <div class="min-h-screen h-full w-[96%] mx-[2%] my-[3%] px-[2%] py-[3%] rounded  shadow-gray-300 shadow-sm">
          <h4 class="text-3xl text-black font-bold mb-[3%]">Gestion des titres</h4>
          <div class="block md:flex">
            <AddTitreVue/>
            <UpdateTitreVue/>
          </div>
    </div>
    </div>
  </template>
  
  <script>
  import AddTitreVue from './AddTitreVue.vue';
  import UpdateTitreVue from './UpdateTitreVue.vue';
  
      export default{
        components:
        {
          AddTitreVue,
          UpdateTitreVue
        },
        data()
        {
          return {
            user_role:localStorage.getItem('role'),
          }
        }
      }
  </script>
  
  <style>
  
  </style>