<template>
  <div class="w-full p-O m-0">
    <div class="min-h-screen h-full w-[96%] mx-[2%] my-[2%] px-[2%] py-[1%] rounded  shadow-gray-300 shadow-sm">
        <h4 class="text-2xl md:text-3xl text-black font-bold mb-[2%]">Gestion des activités</h4>
        <div class="block md:flex">
          <AddActivityVue/>
          <UpdateActivityVue/>
        </div>
        
  </div>
  </div>
</template>

<script>
    
    import AddActivityVue from './AddActivityVue'
import UpdateActivityVue from './UpdateActivityVue.vue';
    

export default {
  name: 'ActivityVue',
  components: {
    AddActivityVue,
    UpdateActivityVue
  },
 
}
</script>

<style>

</style>