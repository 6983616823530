<template>
  <div v-if="erreur!='Aucun contenu disponible'" class="h-max min-h-screen w-full mt-[7%] px-[7%] py-[5%] bg-white bg-opacity-50" id="apropos">
        <div class="h-full">
            <div class="w-full md:flex  font-medium font-serif">
                <h4 class="text-5xl md:text-7xl lg:text-8xl font-bold text-green-900 content-center mb-7">{{titre}}</h4>
                <img class=" w-full md:w-[20%]  rounded-lg ml-auto mt-[8%]  object-cover object-top" :src="image">
            </div>
            <p class="mb-3 text-base overflow-x-wrap mt-[5%] break-all text-justify leading-relaxed" v-html="contenu">
                  
            </p>
            


            
        </div>
    </div>
</template>

<script>
  
  import axios from 'axios'
  export default{
    name: 'ApropoVue',
    data() {
      return {
        titre: '',
        contenu: '',
        image:'',
        erreur:""
      };
    },
    mounted() {
        this.getApropos()
    },
    beforeUnmount() {
     this.getApropos()
     
      
    },
    methods: {
     
      getApropos()
      {
        
         
        axios.get(this.$serverConstant+'section/apropos').then(response=>
        {
          
        
          var data=response.data.data;
          var nbResult=Object.keys(data).length
          
          
          if(nbResult>0)
          {
            data=data[0]
            this.titre=data.titre
            this.contenu=data.contenu
            this.image=this.$storageConstant+data.image
            
          }
          else
          {
            
           
            this.erreur="Aucun contenu disponible"  
            //console.log("nullos")
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
          
           this.erreur=error.response.data.message
        })
       
      }
    }
  }
</script>

<style>
  .logo
  {
    /* background-image: url("./../assets/logo.png"); */
    background-position: top center;
  }
</style>