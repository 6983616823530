<template>
    <div class="relative w-full h-screen overflow-hidden" @mouseover="stopAutoSlide" @mouseout="startAutoSlide">
      <div
        v-for="(item, index) in carouselItems"
        :key="index"
        class="absolute top-0 left-0 w-full h-full opacity-0  transition-opacity duration-1000"
        :class="{ 'opacity-100': currentIndex === index }"
      >
        <img :src="item.imageUrl" alt="" class="w-full h-full object-cover object-top" />
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white">
            <div class="bg-black bg-opacity-20  rounded-sm shadow mb-14 p-10">
                <h3 class="text-5xl px-1 md:text-6xl font-bold mb-4 text-white">{{ item.title }}</h3>
            
                <p class="text-xl mb-10 text-gray-100 font-bold" v-html="item.description"></p>
                <a :href="item.buttonLink" class="bg-green-800 text-xl hover:bg-green-500   px-4 py-4 rounded-full text-white">{{ item.buttonText }}</a>
            </div>
          
            
        </div>
      </div>
      <div class="absolute top-1/2 -translate-y-1/2 w-full flex justify-between z-10">
        <button
          class="text-2xl bg-gray-500 bg-opacity-50 rounded-md text-white p-4 cursor-pointer"
          @click="prevSlide"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="text-2xl bg-gray-500 bg-opacity-50 rounded-md text-white p-4 cursor-pointer"
          @click="nextSlide"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
      <div class="absolute bottom-5 left-1/2 -translate-x-1/2 flex justify-center z-10">
        <button
          v-for="(item, index) in carouselItems"
          :key="index"
          class="w-3 h-3 bg-gray-400 rounded-full mx-2 cursor-pointer"
          :class="{ 'bg-white': currentIndex === index }"
          @click="goToSlide(index)"
        ></button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    data() {
      return {
        currentIndex: 0,
        carouselItems: this.getActivities(),
        erreur:""
      };
    },
    mounted() {
      this.startAutoSlide();
    },
    beforeUnmount() {
      this.stopAutoSlide();
      this.getActivities()
      
    },
    methods: {
      startAutoSlide() {
        this.autoSlideInterval = setInterval(() => {
          this.nextSlide();
        }, 5000);
      },
      stopAutoSlide() {
        clearInterval(this.autoSlideInterval);
      },
      prevSlide() {
      if (this.carouselItems && this.carouselItems.length > 0) {
        this.currentIndex = (this.currentIndex - 1 + this.carouselItems.length) % this.carouselItems.length;
      }
      },
      nextSlide() {
      if (this.carouselItems && this.carouselItems.length > 0) {
        this.currentIndex = (this.currentIndex + 1) % this.carouselItems.length;
      }
      },
      goToSlide(index) {
      if (this.carouselItems && this.carouselItems.length > 0 && index >= 0 && index < this.carouselItems.length) {
        this.currentIndex = index;
      }
      },

      getActivities()
      {
        
        var custom_activities = [
          {
            title: "Soutenez une cause qui vous passionne",
            description:
              "Rejoignez nous dans l'encadrement des hommes, des femmes, des jeunes garçons et filles sans emploi, des orphelins, des vieillards et des filles mères pour promouvoir l'encadrement, la formation, l'éducation permanente en faveur des personnes vulnérables",
            buttonText: "Devenir membre",
            buttonLink: "whatsapp://send?phone=243976804996&text= Mes salutations, Je suis intéressé à participer et faire des dons. J'aimerais en savoir davantage.",
            imageUrl: require("./../assets/support.jpg")
          },
          {
            title: "Formez vous aux métiers pratiques et professionnels",
            description:
              "Inscrivez-vous dans notre centre de formation pour apprendre l'informatique, la coupe et couture et autres métiers",
            buttonText: "S'inscrire à une formation",
            buttonLink: "whatsapp://send?phone=243976804996&text= Mes salutations, Je suis intéressé(e) par vos formations. J'aimerais en savoir davantage.",
            imageUrl: require("./../assets/couture.jpg")
          },
          {
            title: "Des produits qui peuvent changer votre quotidien",
            description:
              "Nous metons à votre disposition des produits de bien être et autres pour améliorer votre vie au quotidien",
            buttonText: "Commander",
            buttonLink: "whatsapp://send?phone=243976804996&text= Mes salutations, Je suis intéressé(e) par vos produits. J'aimerais en savoir davantage.",
            imageUrl: require("./../assets/produit.jpg")
          }
        ] 
        axios.get(this.$serverConstant+'activity').then(response=>
        {
          
          var activity_data=response.data.data;
          var nbResult=Object.keys(activity_data).length
          
          
          if(nbResult>0)
          {
            var activity =[]
            for (var i=0; i<nbResult; i++)
            {

               activity.push(
              {
                title:activity_data[i].titre,
                description:activity_data[i].contenu,
                buttonText:activity_data[i].btntext,
                buttonLink:"whatsapp://send?phone=243976804996&text="+activity_data[i].message,
                imageUrl: this.$storageConstant+activity_data[i].image
              }
           
            )
            }
            
            this.carouselItems=activity
            
            
          }
          else
          {
            
           
            this.carouselItems=custom_activities
            //console.log("nullos")
          }
          
          //console.log(response.data)
          //this.erreur=response.data.message
          
        }).catch(error=>
        {
           // Une erreur dans l'api
           this.carouselItems=custom_activities
           this.erreur=error.response.data.message
        })
       
      }
    }
  };
  </script>